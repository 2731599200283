import React from 'react'
import Blog from './blogs'
import Green from './last'
const Blogs = () => {
  return (
    <div className='careers_gradient_test'>
      <Blog />
      <Green />
    </div>
  )
}

export default Blogs
