import React from 'react'
import Casestudytop from './casestudy'


const Casestudy = () => {
  return (
    <div>
      <Casestudytop />
    </div>
  )
}

export default Casestudy
