import React from 'react'
import Form from './form1'
import Formbanner from './formbanner'

const Contactus = () => {
  return (
    <div>
      
      <Formbanner className="custom_contact_settings" />
      <Form />
      

    </div>
  )
}

export default Contactus
