import React from 'react'
import Main from './main'

const Careers = () => {
  return (
    <div className='careers_gradient_test'>
      
      <Main />

    </div>
  )
}

export default Careers
