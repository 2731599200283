import React from 'react'
import Home from './home'


const HomePage = () => {
  return (
    <div>
      
      <Home />

    </div>
  )
}

export default HomePage
